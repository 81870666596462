var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-files-tb"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"project-messages-wrapper"},[_c('section',{staticClass:"messages-heading-top"},[_c('div',{staticClass:"top-area"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-8 col-sm-6"},[_c('div',{},[_c('h4',{staticClass:"general-sub-heading"},[_vm._v(" Messages "),_c('a',{staticClass:"refreshData",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshProjectMessages.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})])])])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-6"},[_c('div',{staticClass:"general-filters form-area text-right"},[_c('button',{staticClass:"btn btn-primary default-global-btn",on:{"click":_vm.showHideMessageModel}},[_vm._v(" Create a New Thread ")])])])])]),_c('message-header')],1),_c('section',{staticClass:"upload-file-form-area"},[_c('transition',{attrs:{"name":"slide"}},[_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMessageModel),expression:"showMessageModel"}],staticClass:"main-thread-message-form",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"thread_title"}},[_vm._v("Enter Thread Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.thread_title),expression:"formData.thread_title"}],staticClass:"form-control",class:{
                  'input-border-errors':
                    _vm.$v.formData.thread_title.$dirty &&
                    !_vm.$v.formData.thread_title.required
                },attrs:{"type":"text"},domProps:{"value":(_vm.formData.thread_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "thread_title", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"messagebody"}},[_vm._v("Enter Thread Body")]),_c('ckeditor',{class:{
                  'input-border-errors':
                    _vm.$v.formData.messagebody.$dirty &&
                    !_vm.$v.formData.messagebody.required
                },attrs:{"config":_vm.editorConfig},model:{value:(_vm.formData.messagebody),callback:function ($$v) {_vm.$set(_vm.formData, "messagebody", $$v)},expression:"formData.messagebody"}})],1),_c('dropzone',{ref:"createPost",attrs:{"id":"foo","options":_vm.options},on:{"vdropzone-sending":_vm.sendingEvent,"vdropzone-success":_vm.successEvent,"vdropzone-error":_vm.errorEvent,"vdropzone-success-multiple":_vm.multipleUploadComplete,"vdropzone-error-multiple":_vm.multipleServerError}}),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Create Message ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])],1)])],1),_c('section',{staticClass:"thread-listing"},[(
            typeof _vm.project_messages.data !== 'undefined' &&
              Object.keys(_vm.project_messages.data).length
          )?_c('div',{staticClass:"message-thread-wrapper"},[(_vm.sub_loader)?_c('div',{staticClass:"white-row loader-route subloader"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_vm._e(),_vm._l((_vm.project_messages.data),function(message,mes_index){return _c('div',{key:mes_index,staticClass:"thr-loop-row margin-top-20"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12 col-sm-12"},[_c('div',{staticClass:"thrd-name"},[_c('router-link',{staticClass:"eclipse-para global-para-linkcolor-blk customize-global-link gl-lk-blk",attrs:{"to":{
                      path:
                        '/projects/show/' +
                        _vm.$route.params.project_id +
                        '/messages/show/' +
                        message.id
                    },"tag":"a"}},[_vm._v(" "+_vm._s(message.porject_thread_subtitle)+" ")])],1)]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-8 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-12"},[_c('div',{staticClass:"per-name"},[_c('p',{staticClass:"eclipse-para"},[_vm._v(" "+_vm._s(message.last_message.user.name)+" ")])])]),_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-sm-12"},[_c('div',{staticClass:"pers-last-mesg"},[_c('p',{staticClass:"eclipse-para"},[_vm._v(" "+_vm._s(message.last_message.message)+" ")])])])])]),_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-4 col-sm-12"},[_c('div',{staticClass:"thr-time text-right"},[_vm._v(" "+_vm._s(message.last_message.created_at)+" ")])])])])})],2):_c('div',{staticClass:"message-thread-wrapper"},[_c('div',{staticClass:"thr-loop-row margin-top-20"},[_c('no-record-found')],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }