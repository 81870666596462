import { render, staticRenderFns } from "./salesperson_messages.vue?vue&type=template&id=1a2d2d32&scoped=true&"
import script from "./salesperson_messages.vue?vue&type=script&lang=js&"
export * from "./salesperson_messages.vue?vue&type=script&lang=js&"
import style0 from "./salesperson_messages.vue?vue&type=style&index=0&id=1a2d2d32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2d2d32",
  null
  
)

export default component.exports