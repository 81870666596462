var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-files-tb"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"project-messages-wrapper"},[_c('section',{staticClass:"messages-heading-top"},[_c('div',{staticClass:"top-area"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-6"},[_c('div',{},[_c('h4',{staticClass:"general-sub-heading"},[_vm._v(" Messages "),_c('a',{staticClass:"refreshData",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshProjectMessages.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})])])])])])])]),_c('section',{staticClass:"thread-listing"},[(
            typeof _vm.project_messages.data !== 'undefined' &&
              Object.keys(_vm.project_messages.data).length
          )?_c('div',{staticClass:"message-thread-wrapper"},[(_vm.sub_loader)?_c('div',{staticClass:"white-row loader-route subloader"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_vm._e(),_vm._l((_vm.project_messages.data),function(message,mes_index){return _c('div',{key:mes_index,staticClass:"thr-loop-row margin-top-20"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12 col-sm-12"},[_c('div',{staticClass:"thrd-name"},[_c('router-link',{staticClass:"eclipse-para global-para-link2",attrs:{"to":{
                      path:
                        '/projects/show/' +
                        _vm.$route.params.project_id +
                        '/messages/show/' +
                        message.id
                    },"tag":"p"}},[_vm._v(" "+_vm._s(message.porject_thread_subtitle)+" ")])],1)]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-8 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-3 col-md-3 col-sm-12"},[_c('div',{staticClass:"per-name"},[_c('p',{staticClass:"eclipse-para"},[_vm._v(" "+_vm._s(message.last_message.user.name)+" ")])])]),_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-sm-12"},[_c('div',{staticClass:"pers-last-mesg"},[_c('p',{staticClass:"eclipse-para"},[_vm._v(" "+_vm._s(message.last_message.message)+" ")])])])])]),_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-4 col-sm-12"},[_c('div',{staticClass:"thr-time text-right"},[_vm._v(" "+_vm._s(message.last_message.created_at)+" ")])])])])})],2):_c('div',{staticClass:"message-thread-wrapper"},[_c('div',{staticClass:"thr-loop-row margin-top-20"},[_c('no-record-found')],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }